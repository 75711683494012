import req from "../methods/req"
import method from '../methods/method'

// 获取列表
export const getContractList = function (data){
    return method.get_list({
        url:'/manage/crm.freezer/getContractList',
        ...data
    })
}

export const getContractInfo = function (data){
	return req({
		url:'/manage/crm.freezer/getContractInfo',
		...data,
	})
}
// 获取搜索状态
export const getStatusSelect = function (data){
	return req({
		url:'/manage/crm.freezer/getStatusSelect',
		...data,
	})
}
// 获取审批列表
export const getBindingApprovalList = function (data){
    return method.get_list({
        url:'/manage/crm.freezer/getBindingApprovalList',
        ...data
    })
}


